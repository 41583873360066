import { Logged } from '@/engine/cases/Logged';
import { wrap } from '@/engine/wrap';
// import { api } from '@/modules/services/api';
import { LastProductsList } from '@/screen/atoms/home/LastProductsList';
import { Main } from '@/screen/atoms/home/Main';
import { SEO } from '@/screen/components/forward/SEO';
import { DefaultLayout } from '@/screen/layouts/DefaultLayout';

const Home = (): JSX.Element => {
  return (
    <>
      <SEO />

      <div className="py-8 bowl-content space-y-12">
        <Main />
        <LastProductsList />
      </div>
    </>
  );
};

export default wrap(Home, {
  layout: DefaultLayout,
  components: [Logged],
});
