import type { GetLastProductsHttpData } from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { useMemo } from 'react';
import useSWR from 'swr';

import { api } from '@/modules/services/api';
import { Link } from '@/screen/components/forward/Link';
import { Button } from '@/screen/components/ui/Button';
import {
  ProductCard,
  ProductCardShimmer,
} from '@/screen/components/ui/ProductCard';

import styles from './styles.module.css';

const productsLoader = Array.from({ length: 3 }, (_, i) => i).map(key => (
  <ProductCardShimmer key={key} />
));

const fetcher = (): Promise<GetLastProductsHttpData> =>
  api
    .get<GetLastProductsHttpData>('/products/last-products')
    .then(({ data }) => data);

export const LastProductsList = (): JSX.Element => {
  const { error, data } = useSWR<GetLastProductsHttpData>('products', fetcher);

  const products = useMemo(() => data ?? [], [data]);

  const loading = useMemo(
    () => (!error && !data) || !products.length,
    [error, data, products],
  );

  const productsElements = useMemo(
    () =>
      products.map(product => <ProductCard key={product.id} data={product} />),
    [products],
  );

  return (
    <section>
      <div className={styles['products-container']}>
        {loading ? productsLoader : productsElements}
      </div>

      <div className="mt-8 flex justify-center">
        <Button asProp={Link} href="/products">
          Ver todos os produtos
        </Button>
      </div>
    </section>
  );
};
