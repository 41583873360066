import styled from '@emotion/styled';
import { mergeClassNames } from '@hitechline/reactools';

import { makePolymorphicComponent } from '@/screen/components/forward/makePolymorphicComponent';
import { BaseButton } from '@/screen/components/ui/BaseButton';

interface ButtonProps {
  loading?: boolean;
}

export const Button = makePolymorphicComponent<ButtonProps, 'button'>(
  'button',
  ({ PolymorphicElement, className, children, loading, ...props }) => {
    return (
      <BaseButton
        {...props}
        disabled={loading}
        asProp={PolymorphicElement}
        className={mergeClassNames(
          'px-12 w-full bg-floor-green text-white max-w-wai',
          className,
        )}
      >
        {loading ? <Spinner color="#fff" className="spinner" /> : children}
      </BaseButton>
    );
  },
);

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  margin: 0 auto;

  border: 2px solid transparent;
  animation: spinner 300ms linear infinite;

  border-top: 2px solid #fff;
  border-left: 2px solid #fff;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
